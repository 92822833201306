import React, { lazy, useState, useEffect, startTransition, useCallback } from "react";
import './App.css';
import { Toaster } from 'react-hot-toast';
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { toast } from 'react-hot-toast';
import api from "./Api";
import AddsModal from "./components/AddsModal";
import FlightInformation from "./FlightInformation";
import { useFlight } from "./context/FlightContext";
import Landing from "./components/layouts/Landing";
import Dashboard from "./components/layouts/Dashboard";
import { useUser } from "./context/UserContext";

const Home = lazy(() => import("./Home"));
const DashHome = lazy(() => import("./components/DashPages/DashHome"));
const SearchResults = lazy(() => import("./SearchResults"));
const Checkout = lazy(() => import("./Checkout"));
const Book = lazy(() => import("./Book"));
const PaymentConfirm = lazy(() => import("./PaymentConfirm"));
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const Login = lazy(() => import("./Login"));
const Ticket = lazy(() => import("./components/Ticket"));
const Users = lazy(() => import("./components/DashPages/users/Users"));
const CreateUser = lazy(() => import("./components/DashPages/users/CreateUser"));
const UserProfile = lazy(() => import("./components/DashPages/users/UserProfile"));
const Agents = lazy(() => import("./components/DashPages/users/Agents"));
const AgentTypes = lazy(() => import("./components/DashPages/users/AgentTypes"));
const Commissions = lazy(() => import("./components/DashPages/users/Commissions"));
const CommissionTypes = lazy(() => import("./components/DashPages/users/CommissionTypes"));
const Currencies = lazy(() => import("./components/DashPages/users/Currencies"));
const CurrencyConversions = lazy(() => import("./components/DashPages/users/CurrencyConversions"));
const Roles = lazy(() => import("./components/DashPages/users/Roles"));
const CommissionByUser = lazy(() => import("./components/DashPages/users/CommissionByUser"));
const CreateTravelAgency = lazy(() => import("./components/DashPages/users/CreateAgent"));
const CreateRole = lazy(() => import("./components/DashPages/users/CreateRole"));
const CreateAgentType = lazy(() => import("./components/DashPages/users/CreateAgentType"));
const CreateCommissionType = lazy(() => import("./components/DashPages/users/CreateCommissionType"));
const CreateCurrency = lazy(() => import("./components/DashPages/users/CreateCurrency"));
const ChangePassword = lazy(() => import("./components/DashPages/users/ChangePassword"));
const CreateCurrencyConversion = lazy(() => import("./components/DashPages/users/CreateCurrencyConversion"));
const UpdateCurrencyConversion = lazy(() => import("./components/DashPages/users/UpdateCurrencyConversion"));


function App() {
  // Data and States
  const [airports, setAirports] = useState([]);
  const [loading, setLoading] = useState(true);
  const { updateFlight } = useFlight();
  const [showAdds, setShowAdds] = useState(false);
  const {user} = useUser();
  

  // Methods
  useEffect(() => {
    const getData = async () => {
      try {
        let response = await api.consume('/airports');
        if (response.status === 200) {
          startTransition(() => {
            setAirports(response.airPortList);
            setLoading(false); 
          });
        } else {
          toast.error("Error: Failure to get airport list");
          setLoading(false); 
        }
      } catch (error) {
        toast.error('Error: ' + error);
        setLoading(false); 
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (airports.length > 0) {
      localStorage.setItem('airports', JSON.stringify(airports));
      updateFlight(airports)
    }
  }, [airports, updateFlight]);

  const handleViewAdd = useCallback((e) => {
      e?.stopPropagation();
      setShowAdds(!showAdds);
  }, [showAdds]);

  const handleViewClose = useCallback((e) => {
      e?.stopPropagation();
      setShowAdds(false);
  }, []);

  return (
    <div className="App relative">
      <div>
        <BrowserRouter>
          <div className="">
            <Routes>
              <Route path="/" element={<Landing loading={loading} showAdds={showAdds} />}>
                <Route exact path="/" element={<Home handleViewAdd={handleViewAdd} handleViewClose={handleViewClose} />} />
                <Route exact path="/search-results" element={<SearchResults handleViewAdd={handleViewAdd} handleViewClose={handleViewClose} />} />
                <Route exact path="/flight-information" element={<FlightInformation />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/complete-booking" element={<Book />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/payment/ticket/:pnr" element={<Ticket />} />
                <Route exact path="/payments/ipay" element={<PaymentConfirm />} />
              </Route>

              <Route
                path="/"
                  element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                  }
                  >
                    {user?.role === 'AGENT' ? (
                      <>
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path={`user/:id`} element={<UserProfile />} />
                        <Route path="currencies" element={<Currencies />} />
                        <Route path="currency-conversions" element={<CurrencyConversions />} />
                      </>
                    ) :  (
                      <>
                        <Route path="dashboard" element={<DashHome />} />
                        <Route path="users" element={<Users />} />
                        <Route path="create-user" element={<CreateUser />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="/user/:id" element={<UserProfile />} />
                        <Route path="agents" element={<Agents />} />
                        <Route path="create-agent" element={<CreateTravelAgency />} />
                        <Route path="agent-types" element={<AgentTypes />} />
                        <Route path="create-agent-type" element={<CreateAgentType />} />
                        <Route path="commissions" element={<Commissions />} />
                        <Route path="commission-by-user" element={<CommissionByUser />} />
                        <Route path="create-commission-type" element={<CreateCommissionType />} />
                        <Route path="commission-types" element={<CommissionTypes />} />
                        <Route path="currencies" element={<Currencies />} />
                        <Route path="create-currency" element={<CreateCurrency />} />
                        <Route path="currency-conversions" element={<CurrencyConversions />} />
                        <Route path="create-currency-conversion" element={<CreateCurrencyConversion />} />
                        <Route path="update-currency-conversion" element={<UpdateCurrencyConversion />} />
                        <Route path="roles" element={<Roles />} />
                        <Route path="create-role" element={<CreateRole />} />
                      </>
                    ) }
              </Route>
            </Routes>
            {showAdds && <AddsModal handleViewAdd={handleViewAdd} />}
          </div>
          <TitleUpdater />

        </BrowserRouter>
      </div>
      <Toaster />
    </div>
  );
}

const TitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const titles = {
      '/': 'Hayaan Travel Solution - Home',
      '/search-results': 'Hayaan Travel Solution - Search Results',
      '/flight-information': 'Hayaan Travel Solution - Flight Information',
      '/checkout': 'Hayaan Travel Solution - Checkout',
    };

    document.title = titles[location.pathname] || 'Hayaan Travel Solution';
  }, [location.pathname]);

  return null;
};

export default App;
